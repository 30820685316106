/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "intangibilizacaoApi",
            "endpoint": "https://aqf45cw75i.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "intangibilizacaoGscriptApi",
            "endpoint": "https://rx49arj3g0.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-1:dc82195c-cedc-4011-83b1-0c971b3603b2",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_tLh2mfNNF",
    "aws_user_pools_web_client_id": "3mmvdsmofioa6chh5oja0natjv",
    "oauth": {
        "domain": "vitta-prod-hub-intangibilizacao-prod.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://www.intangibilizacao.hub.vitta.com.br/",
        "redirectSignOut": "https://www.intangibilizacao.hub.vitta.com.br/",
        "responseType": "token"
    },
    "federationTarget": "COGNITO_USER_AND_IDENTITY_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "intangibilizacao-prod",
            "region": "us-east-1"
        }
    ]
};


export default awsmobile;
