<template>
  <v-app>
    <amplify-auth-container>
      <amplify-authenticator>
        <div slot="sign-in"></div>
      </amplify-authenticator>
    </amplify-auth-container>
    <v-container v-if="authState === 'signedin'">
      <v-app-bar app>
        <v-toolbar-title>Intagibilização</v-toolbar-title>
        <v-spacer></v-spacer>
        <amplify-sign-out></amplify-sign-out>
      </v-app-bar>
      <v-main>
        <Home />
      </v-main>
    </v-container>
  </v-app>
</template>

<script>
import { onAuthUIStateChange } from '@aws-amplify/ui-components';
import { Auth } from 'aws-amplify';

import Home from './components/Home.vue';
/** grNuL4M7FzguTRC */
export default {
  name: 'App',

  components: {
    Home,
  },

  created() {
    this.unsubscribeAuth = onAuthUIStateChange((authState, authData) => {
      this.authState = authState;
      this.user = authData;

      if (authState === 'signin') {
        this.federatedSignIn('Google');
      }
    });
  },

  beforeDestroy() {
    this.unsubscribeAuth();
  },

  data() {
    return {
      user: undefined,
      authState: undefined,
      unsubscribeAuth: undefined,
      drawer: null,
    };
  },
  methods: {
    federatedSignIn(provider) {
      Auth.federatedSignIn({ provider });
    },
  },
};
</script>
