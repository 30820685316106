<template>
  <v-container>
    <v-toolbar dense flat>
      <v-toolbar-title>Vittanos</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon>
        <v-icon>mdi-download</v-icon>
      </v-btn>
      <v-dialog
        ref="dialog"
        v-model="modal"
        :return-value.sync="date"
        persistent
        width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="date"
            prepend-icon="mdi-calendar"
            readonly
            hide-details
            v-bind="attrs"
            v-on="on"
            style="max-width: 100px"
          ></v-text-field>
        </template>
        <v-date-picker v-model="date" type="month" scrollable>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="modal = false"> Cancel </v-btn>
          <v-btn text color="primary" @click="updateDate($refs, date)">
            OK
          </v-btn>
        </v-date-picker>
      </v-dialog>
      <!-- <v-btn class="ml-4" small color="secondary" @click="promptLoadEvidences = true">Carregar Evidências</v-btn> -->
      <v-btn icon>
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
    </v-toolbar>
    <br />
    <v-row>
      <v-col>
        <v-expansion-panels v-if="people.length > 0">
          <v-expansion-panel v-for="(person, i) in people" :key="i">
            <v-expansion-panel-header>
              {{ person.name }}
              <span class="text-right">
                <v-icon v-if="person.percentage < 80" color="error">
                  mdi-alert-circle
                </v-icon>
                {{ person.percentage }}%
              </span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row >
                <v-col align="end">
                  <v-btn class="mr-2" color="secondary" small @click="openNewIssueDialog(person)"
                    >Adicionar nova issue</v-btn
                  >
                </v-col>
              </v-row>
              <template v-for="(issue, j) in person.issues">
                <v-row
                  :key="j"
                  style="font-size: 80%"
                  :class="j % 2 === 0 ? 'grey lighten-3' : null"
                >
                  <v-col cols="8" justify="start">
                    <span class="ma-0 align-center">
                      <a
                        :href="`https://vitta-dev.atlassian.net/browse/${issue.key}`"
                        target="_blank"
                      >
                        {{ issue.key }}
                      </a>
                      - {{ issue.summary }}
                    </span>
                  </v-col>
                  <v-col align="end">
                    <span
                      class="text-right ma-0"
                      :class="['Bug', 'Incidente'].includes(issue.issueType) ? 'red--text' : null"
                    >
                      <v-text-field 
                        single-line
                        hide-details="true"
                        v-model="issue.duration"
                        class="ma-0 pa-0 d-inline-flex duration"
                        style="width: 100px;text-align: right"
                        suffix="min">
                      </v-text-field>
                      <v-tooltip top v-if="['Bug', 'Incidente'].includes(issue.issueType)">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            color="error"
                            dark
                            v-bind="attrs"
                            v-on="on"
                            small
                          >
                            mdi-bug
                          </v-icon>
                        </template>
                        <span
                          >Bugs não são considerados para intangibilização</span
                        >
                      </v-tooltip>
                    </span>
                    <v-btn @click="updateIssue(person, issue)" class="mx-1" text x-small title="Salvar">Salvar</v-btn>
                    <v-menu
                      bottom
                      left
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          text
                          x-small
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                      </template>
                      <v-list dense>
                        <v-list-item @click="openEvidencesDialog(issue)">
                          <v-list-item-content >
                            Evidências
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-col>
                </v-row>
              </template>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-alert
          v-else
          dense
          outlined
          prominent
          type="warning"
        >Não há nenhuma intangibilização nessa competência</v-alert>
      </v-col>
    </v-row>
    <v-dialog
      v-model="newIssueDialog"
      max-width="490"
    >
      <v-card>
        <v-card-title class="text-h5">
          Nova issue
        </v-card-title>
        <v-card-text>
          <v-text-field 
            single-line
            label="Key"
            outlined
            v-model="newIssue.key"
            class="mr-2 d-inline-flex "
            style="width: 150px;text-align: right"
            placeholder="Key">
          </v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary darken-1"
            text
            @click="newIssueDialog = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="primary darken-1"
            @click="saveNewIssue()"
          >
            Carregar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="evidencesDialog"
      max-width="490"
    >
      <v-card>
        <v-card-title class="text-h5">
          Evidências
        </v-card-title>

        <v-card-text>
          <v-timeline
            v-if="selectedIssue.evidences.length > 0"
            dense
            clipped
          >
          <template v-for="(evidence, k) in selectedIssue.evidences">
            <div :key="k">
              <v-timeline-item
                class="mb-4"
                color="grey"
                icon-color="grey lighten-2"
                small
              >
                <v-row justify="space-between">
                  <v-col cols="7">
                    <b>{{evidence.date}}</b>
                    <br/>
                    {{prettyText(evidence.type)}}
                  </v-col>
                  <v-col
                    class="text-right"
                    cols="5"
                  >
                    {{(evidence.duration/60).toFixed(2)}}h
                  </v-col>
                </v-row>
              </v-timeline-item>
            </div>
          </template>
          </v-timeline>
          <p v-else>Nenhuma evidência encontrada</p>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="emptyIntangibilizacaoResponseDialog"
      persistent
      max-width="320"
    >
      <v-card>
        <v-card-title class="text-h5">
          Aviso
        </v-card-title>
        <v-card-text>Não existe nenhuma intangibilização para essa competência. <br/><br/> <b>Deseja carregar as evidências?</b></v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary darken-1"
            text
            @click="emptyIntangibilizacaoResponseDialog = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="primary darken-1"
            @click="emptyIntangibilizacaoResponseDialog = false; promptLoadEvidences = true"
          >
            Carregar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="promptLoadEvidences"
      persistent
      max-width="320"
    >
      <v-card>
        <v-card-title class="text-h5">
          Você tem certeza?
        </v-card-title>
        <v-card-text>
          Essa operação irá carregar todas as evidências para intangibilização.
          <br/><br/>
          <b>ESSA OPERAÇÃO IRÁ APAGAR QUALQUER LANÇAMENTO MANUAL PREVIAMENTE REALIZADO NESSA COMPETÊNCIA</b>
          <br/><br/>
          Deseja continuar?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary darken-1"
            text
            @click="promptLoadEvidences = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="primary darken-1"
            @click="loadEvidences()"
          >
            Carregar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import { API } from "aws-amplify";
export default {
  name: "Home",

  data() {
    return {
      people: [],
      date: new Date().toISOString().substr(0, 7),
      menu: false,
      modal: false,
      overlay: false,
      evidencesDialog: false,
      newIssueDialog: false,
      emptyIntangibilizacaoResponseDialog: false,
      promptLoadEvidences: false,
      newIssue: {},
      selectedPerson: {},
      selectedIssue: {
        evidences: []
      },
    };
  },
  methods: {
    prettyText(type) {
      switch(type) {
        case 'development':
          return 'Desenvolveu uma issue';
        case 'rank':
          return 'Priorizou uma issue';
        case 'sprint':
          return 'Iniciou uma Sprint';
        case 'manual':
          return 'Lançamento Manual';
        default: 
          return 'Não identificado'
      }
    },
    updateDate(ref, date) {
      ref.dialog.save(date);
      this.loadIntangibilizacao();
    },
    openNewIssueDialog(person) {
      this.selectedPerson = person;
      this.newIssueDialog = true;
    },
    openEvidencesDialog(issue) {
      this.selectedIssue = issue;
      this.evidencesDialog = true;
    },
    async updateIssue(person, issue) {
      this.overlay = true;

      const evidenceManual = issue.evidences.find((evidence) => evidence.type === 'manual');
      if (!evidenceManual) {
        issue.evidences.push({
          type: 'manual',
          author: ':user',
          date: new Date().toISOString(),
          duration: issue.duration
        });
      } else {
        evidenceManual.duration = issue.duration;
      }

      let totalDuration = 0;
      let workDuration = 0;
      person.issues.forEach((issue) => {
        totalDuration += parseFloat(issue.duration);
        if (!['Bug', 'Incidente'].includes(issue.issueType)) {
          workDuration += parseFloat(issue.duration);
        }
      });

      person.workDuration = workDuration;
      person.totalDuration = totalDuration;

      if (totalDuration === 0) {
        person.percentage = (0.00).toFixed(2);
      } else {
        person.percentage = ((workDuration / totalDuration) * 100).toFixed(2);
      }

      await API.post("intangibilizacaoApi", "/intangibilizacao/update", {
        timeout: 99999,
        body: person
      });

      this.overlay = false;
    },
    async saveNewIssue() {
      this.overlay = true;
      this.newIssueDialog = false;
      
      try {
        const issue = await API.get("intangibilizacaoApi",`/evidences/jira/load/${this.newIssue.key}`);
        this.selectedPerson.issues.push(issue);
      } catch (e) {
        alert('Houve um erro!');
      }
      this.overlay = false;
    },
    async loadEvidences() {
      this.overlay = true;
      this.emptyIntangibilizacaoResponseDialog = false;
      this.promptLoadEvidences = false;

      await API.get("intangibilizacaoApi", "/evidences/load", {
        timeout: 99999,
        queryStringParameters: {
          year: this.date.substring(0, 4),
          month: this.date.substring(5, 7),
        },
      });

      this.loadIntangibilizacao();

      this.overlay = false;
    },
    async loadIntangibilizacao() {
      this.overlay = true;
      const response = await API.get("intangibilizacaoApi", "/intangibilizacao", {
        timeout: 99999,
        queryStringParameters: {
          year: this.date.substring(0, 4),
          month: this.date.substring(5, 7),
        },
      });

      if (response.Count > 0) {
        this.people = response.Items;
      } else {
        this.emptyIntangibilizacaoResponseDialog = true;
        this.people = [];
      }

      this.overlay = false;
    },
  },

  async mounted() {
    this.loadIntangibilizacao();
  },
};
</script>
<style>
  .duration {
    font-size: 80%;
  }
  .duration .v-text-field__suffix{
    font-size: 80%;
  }
  
  .duration input {
    text-align: right;
    font-size: 80%;
  }
  .duration .v-input__slot:before {
    border: 0 !important;
  }
</style>
